import type {ImageProps} from '@/components/base/elements/Image/Image';

import CardStats from '../CardStats/CardStats';

interface GlobeProps {
  statsCards: {
    value: string;
    label: string;
    image?: ImageProps;
  }[];
}

export default function Globe({statsCards}: GlobeProps) {
  return (
    <div className="grid container row-auto lg:flex-col lg:grid-cols-12 z-10">
      <div className="z-10 h-[628px] w-[150%] translate-x-[calc(50vw_-_50%_-_16px)] -translate-y-14 -mb-56 sm:w-auto sm:translate-x-0 md:h-[935px] md:-translate-y-32 md:-mb-64 lg:col-span-6 lg:w-[163%] lg:h-[163%] lg:translate-x-[-39%] lg:translate-y-[-6.5rem] bg-[url('https://cdn.shopify.com/b/shopify-brochure2-assets/ce270fba971d3238d1a1fdddb6982b2c.svg')] bg-contain bg-center bg-no-repeat" />
      <div className="z-20 lg:col-span-6 lg:mt-16">
        <CardStats
          oneColumn
          type="topDivider"
          mode="dark"
          withContainer={false}
          stats={statsCards}
          ratio={0.0676}
        />
      </div>
    </div>
  );
}
